<template>
  <div>
    <div ref="snitch" class="snitch" @click="catchSnitch"></div>

    <!-- Losowe sentencje motywacyjne -->
    <div
      v-for="(quote, index) in activeQuotes"
      :key="index"
      :style="quote.style"
      class="quote"
    >
      {{ quote.text }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'

const motivationalQuotes = [
  'Believe in yourself!',
  'You can do it!',
  'Never give up!',
  'Success is a journey, not a destination.',
  'Stay positive, work hard, make it happen.',
  'Dream big, work hard, stay focused.',
  'You are stronger than you think!',
  'Believe in the power of your dreams.',
]

const activeQuotes = ref<{ text: string; style: any }[]>([])

const showRandomQuote = () => {
  const randomQuote =
    motivationalQuotes[Math.floor(Math.random() * motivationalQuotes.length)]

  // Oblicz wymiary tekstu
  const quoteElement = document.createElement('div')
  quoteElement.style.position = 'absolute'
  quoteElement.style.visibility = 'hidden'
  quoteElement.style.fontSize = '1.5rem'
  quoteElement.style.fontWeight = 'bold'
  quoteElement.innerText = randomQuote
  document.body.appendChild(quoteElement)

  const quoteWidth = quoteElement.offsetWidth
  const quoteHeight = quoteElement.offsetHeight
  document.body.removeChild(quoteElement) // Usuwamy tymczasowy element

  // Ustal losową pozycję, biorąc pod uwagę wymiary tekstu
  const randomX = Math.random() * (window.innerWidth - quoteWidth)
  const randomY = Math.random() * (window.innerHeight - quoteHeight)

  activeQuotes.value.push({
    text: randomQuote,
    style: {
      position: 'absolute',
      top: `${randomY}px`,
      left: `${randomX}px`,
      color: '#42b983',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      opacity: 0,
      animation: 'fadeOut 5s forwards',
    },
  })

  setTimeout(() => {
    activeQuotes.value.shift()
  }, 5000)
}

const moveSnitch = (element: HTMLElement) => {
  const containerWidth = window.innerWidth - 50
  const containerHeight = window.innerHeight - 50

  function randomPosition() {
    const randomX = Math.random() * containerWidth
    const randomY = Math.random() * containerHeight
    return { x: randomX, y: randomY }
  }

  function animate() {
    const { x, y } = randomPosition()
    element.style.top = `${y}px`
    element.style.left = `${x}px`
    setTimeout(animate, Math.random() * 2000 + 1000)
  }

  animate()
}

const catchSnitch = () => {
  const snitch = document.querySelector('.snitch') as HTMLElement
  showRandomQuote()
  moveSnitch(snitch)
}

onMounted(() => {
  const snitch = document.querySelector('.snitch') as HTMLElement
  if (snitch) {
    moveSnitch(snitch)
  }
})
</script>

<style scoped lang="scss">
@keyframes flame {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 165, 0, 0.8),
      0 0 20px rgba(236, 224, 200, 0.6);
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 20px rgba(240, 236, 4, 0.8), 0 0 40px rgba(255, 165, 0, 0.6);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 165, 0, 0.8), 0 0 20px rgba(255, 165, 0, 0.6);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.snitch {
  width: 50px;
  height: 50px;
  background-color: gold;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: top 0.5s ease, left 0.5s ease, box-shadow 0.5s ease;
  animation: flame 1.5s infinite ease-in-out;
  box-shadow: 0 0 20px 5px rgba(255, 223, 0, 0.7),
    0 0 40px 15px rgba(255, 223, 0, 0.4), 0 0 60px 30px rgba(255, 223, 0, 0.2);
  /* Dynamic shadow effect */
  &:hover {
    box-shadow: 0 0 30px 10px rgba(255, 223, 0, 0.8),
      0 0 50px 20px rgba(255, 223, 0, 0.6), 0 0 70px 40px rgba(255, 223, 0, 0.4);
    cursor: pointer;
  }
}

@keyframes flame {
  0% {
    transform: scale(1);
    box-shadow: 0 0 20px rgba(255, 165, 0, 0.8), 0 0 40px rgba(255, 165, 0, 0.6);
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 40px rgba(255, 165, 0, 0.8), 0 0 80px rgba(255, 165, 0, 0.6);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 20px rgba(255, 165, 0, 0.8), 0 0 40px rgba(255, 165, 0, 0.6);
  }
}

.quote {
  font-family: 'Arial', sans-serif;
  position: absolute;
  animation: fadeOut 5s forwards;
  z-index: 99;
  color: #42b983;
  font-size: 1.5rem;
  font-weight: bold;
  width: auto;
  max-width: 500px;
  white-space: nowrap;
  opacity: 1 !important;
}
</style>
