<template>
  <div class="">
    <div class="text-center">
      <h1 class="text-2xl font-bold mb-8">Last Saved Videos</h1>

      <!-- Loading Spinner or Message -->
      <div v-if="loading" class="text-center text-gray-500">Loading...</div>

      <!-- Error Message -->
      <div v-if="error" class="text-red-500 text-center my-4">
        Error: {{ error.message }}
      </div>

      <!-- No Videos Available Message -->
      <div
        v-if="!loading && videos.length === 0"
        class="text-center text-gray-500"
      >
        No videos available.
      </div>
      <YouTube hidden src="brak" />

      <!-- Video List -->
      <div
        v-if="!loading && videos.length > 0"
        class="flex flex-wrap justify-center gap-10"
      >
        <div
          v-for="(video, index) in videos"
          :key="video.id"
          class="relative flex flex-col items-center justify-start bg-customDarkLight"
        >
          <div
            class="relative w-full flex flex-col wi-youtube-wrapper overflow-hidden"
          >
            <!-- YouTube Player -->
            <YouTube
              v-if="apiReady"
              :video-id="video.video_id"
              :src="video.url + '&rel=0&modestbranding=1&controls=0'"
              class="w-full shadow-md wi-youtube-card"
              width="100%"
              height="100%"
              @ready="(event) => onPlayerReady(event, video, index)"
              @state-change="onPlayerStateChange"
            />

            <div
              class="wi-row flex justify-between items-start px-[10px] relative"
            >
              <!-- Video Title and Remaining Time -->
              <p class="mt-2 text-lg font-semibold text-left w-3/4">
                {{ video.title }}
              </p>

              <button
                @click="deleteVideo(video.id, index)"
                class="px-6 py-2 absolute -top-2 right-2 text-base font-medium bg-customDark text-white hover:text-customGreenLight shadow-lg hover:bg-customDarkBlue transition-transform duration-300 ease-in-out transform-gpu"
              >
                Delete
              </button>
            </div>

            <!-- Display formatted remaining time -->
            <p class="text-sm text-gray-500 text-left px-[10px] pb-[10px]">
              {{ formatTime(video.remainingTime) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import YouTube from 'vue3-youtube'
import apiClient from '@/axios'

// Variables to store data and states
const videos = ref<any[]>([])
const loading = ref(false)
const error = ref<Error | null>(null)
const apiReady = ref(false) // Track if YouTube API is ready
const currentVideoIndex = ref(0) // Store the current video index
const playerInstances = ref<any[]>([]) // Store player instances

// Helper function to format time in seconds to hh:mm:ss
const formatTime = (timeInSeconds: number) => {
  // Ensure timeInSeconds is a valid number, default to 0 if it's NaN or undefined
  timeInSeconds = isNaN(timeInSeconds) ? 0 : timeInSeconds

  const hours = Math.floor(timeInSeconds / 3600)
  const minutes = Math.floor((timeInSeconds % 3600) / 60)
  const seconds = Math.floor(timeInSeconds % 60)

  return [
    hours > 0 ? String(hours).padStart(2, '0') : '00',
    String(minutes).padStart(2, '0'),
    String(seconds).padStart(2, '0'),
  ].join(':')
}

// Fetch the videos from API
const fetchVideos = async () => {
  loading.value = true
  error.value = null

  try {
    const response = await apiClient.get('/api/youtube/videos/last?limit=4')
    videos.value = response.data.map((video: any) => ({
      ...video,
      remainingTime: video.duration, // Initialize remaining time to the video duration
    }))
  } catch (err) {
    error.value = err as Error
  } finally {
    loading.value = false
  }
}

// Delete a video by ID
const deleteVideo = async (id: string, index: number) => {
  try {
    await apiClient.delete(`/api/youtube/videos/${id}`)
    videos.value.splice(index, 1) // Remove video from the list
  } catch (err) {
    error.value = err as Error
  }
}

// Load YouTube Iframe API Script
const loadYouTubeAPI = () => {
  if (!window.YT) {
    const tag = document.createElement('script')
    tag.src = 'https://www.youtube.com/iframe_api'
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag)

    window.onYouTubeIframeAPIReady = () => {
      apiReady.value = true
    }
  } else {
    apiReady.value = true
  }
}

// Function to handle when the YouTube player is ready
const onPlayerReady = (event: any, video: any, index: number) => {
  const player = event.target

  // Get video duration and set it in the video object
  const duration = player.getDuration()
  video.duration = duration || video.duration || 0
  video.remainingTime = video.duration // Set initial remaining time to duration

  // Store the player instance
  playerInstances.value[index] = player
}

// Function to handle player state changes
const onPlayerStateChange = (event: any) => {
  const player = event.target
  const videoId = player.getVideoData().video_id
  const video = videos.value.find((v) => v.video_id === videoId)

  if (video) {
    // Update the remaining time of the video every second
    const updateTime = () => {
      if (player.getPlayerState() === window.YT.PlayerState.PLAYING) {
        const currentTime = player.getCurrentTime()
        video.remainingTime = Math.max(video.duration - currentTime, 0)
        requestAnimationFrame(updateTime)
      }
    }

    if (player.getPlayerState() === window.YT.PlayerState.PLAYING) {
      updateTime()
    }

    // If video ended, play the next video
    if (player.getPlayerState() === window.YT.PlayerState.ENDED) {
      playNextVideo()
    }
  }
}

// Function to play the next video
const playNextVideo = () => {
  if (currentVideoIndex.value < videos.value.length - 1) {
    currentVideoIndex.value += 1
    const nextPlayer = playerInstances.value[currentVideoIndex.value]
    nextPlayer.playVideo()
  }
}

// Fetch videos and load YouTube API when the component is mounted
onMounted(() => {
  fetchVideos()
  loadYouTubeAPI()
})
</script>

<style scoped lang="scss">
.container {
  max-width: 1200px;
}

.bg-red-500 {
  background-color: #f56565;
}

.bg-red-700:hover {
  background-color: #c53030;
}

.shadow-lg {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.wi-youtube-wrapper {
  width: 500px !important;
  .wi-youtube-card {
    width: 500px !important;
    height: 280px !important;

    iframe {
      max-height: 280px !important;
    }
  }
}

@media (max-width: 600px) {
  .wi-youtube-wrapper {
    width: 320px !important;
    .wi-youtube-card {
      width: 320px !important;
      height: 300px !important;
      iframe {
        max-height: 200px !important;
      }
    }
  }
}
</style>
