<template>
  <div class="wi-bg flex-center flex-col relative pb-6">
    <!-- Dodany 'relative' -->
    <YoutubeSearch class="" />

    <LastSavedComponent :key="2" />
    <!-- Przełącznik Znicza -->
    <!-- <div class="absolute top-20 right-4 z-100">
      <label class="flex items-center cursor-pointer">
        <span class="mr-2 text-md text-white">Pokaż Znicza</span>
        <input
          type="checkbox"
          v-model="showSnitch"
          class="hidden"
          @change="toggleSnitch"
        />
        <div class="relative">
          <div
            class="block bg-gray-300 w-14 h-8 rounded-full shadow-inner transition duration-300 ease-in-out"
          ></div>
          <div
            class="dot absolute left-1 top-1 bg-green-500 w-6 h-6 rounded-full transition duration-300 ease-in-out"
            :class="{ 'translate-x-6': showSnitch }"
          ></div>
        </div>
      </label>
    </div> -->

    <!-- Znicz wyświetlany na podstawie przełącznika -->
    <SnitchComponent v-if="showSnitch" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import YoutubeSearch from '@/components/YoutubeSearch.vue'
import SnitchComponent from '@/components/SnitchComponent.vue'
import LastSavedComponent from '@/components/LastSavedComponent.vue'

const showSnitch = ref(false)
</script>

<style scoped>
.wi-bg {
  /* background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -84px; */
}

@media screen and (min-width: 900px) {
  .wi-bg {
    /* margin-top: -184px; */
  }
}
</style>
